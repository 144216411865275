/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/mobile-detect@1.4.2/mobile-detect.min.js
 * - /npm/mobile-detect@1.4.2/mobile-detect-modernizr.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/jquery-scrollstop@1.2.0/jquery.scrollstop.min.js
 * - /npm/jquery-mousewheel@3.1.13/jquery.mousewheel.min.js
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.js
 * - /npm/animsition@4.0.2/dist/js/animsition.min.js
 * - /npm/tooltipster@4.2.6/dist/js/tooltipster.bundle.min.js
 * - /npm/tooltipster@4.2.6/dist/js/tooltipster.bundle.min.js
 * - /npm/flexslider@2.7.1/jquery.flexslider-min.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
